<template>
  <div class="health">
    <crumbs :item="title" />
    <div class="banner">
      <div
        class="banner_box"
        v-loading="loading"
        element-loading-text="拼命加载中"
      >
        <img :src="data.contentBanner" alt="" />
      </div>
    </div>
    <div class="health_management">
      <div class="health_management_box">
        <p class="health_title">服务手册概览</p>
        <div class="search">
          <el-input
              @keyup.enter.native="submit"
              clearable
              v-model="value"
              placeholder="输入关键字搜索"
          ></el-input>
          <img @click="submit" src="~@/static/images/search.png" alt="" />
        </div>
      </div>
      <main v-if="total" v-loading="loading" element-loading-text="拼命加载中">
        <ul class="main">
          <li v-for="item in currentPageData" :key="item.zid">
            <a
              :href="item.contentFile"
              target="_blank"
              :download="item.contentTitle"
            >
<!--              <img src="@/static/images/1.jpeg" alt="" />-->
              <img :src="item.contentImg" alt="" />
              <div>
                <p>{{ item.contentTitle }}</p>
                <div class="main_box">
                  <!-- <div v-for="items in item.text" :key="items">
                    {{ items }}
                  </div> -->
                  <pre v-html="item.contentContent"></pre>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div class="paging">
          <p>共有{{ total }}条记录</p>
          <div>
            <img
              @click="prevPage()"
              src="~@/static/images/l_arrow@2x.png"
              alt=""
            />
            <span>{{ currentPage }}/{{ totalPage }}</span>
            <img
              @click="nextPage()"
              src="~@/static/images/ic_arrow_black@2x.png"
              alt=""
            />
          </div>
        </div>
      </main>
      <main v-else>
        <empty></empty>
      </main>
      <div class="line"></div>

      <div class="remind">
        <div>
<!--          *注<br />-->
<!--          <p v-html="data.contentContent"></p>-->

          <Editor
              class="editor"
              :readonly="true"
              :content="data.contentContent"
              :height="0"
          ></Editor>
          <!--          <p>-->
          <!--            1.自2021年2月1日（含）起，所有投保我司特定保险产品并享有-->
          <!--            <a href=""> “重疾绿通Plus”</a> 服务的客户，将享受免费升级为的权益。-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            2.投保我司《安联安康至臻（II）全球团体医疗保险产品》的客户，均享有“第二诊疗意见”服务的使用权益，详情请参见最新版的服务手册，您可以登录自己的会员中心或致电+86-21-61870456进行查询。-->
          <!--          </p>-->
        </div>
      </div>
      <!-- <div class="obtain">
        <div class="obtain_btn">
          <div>更多健康管理方案</div>
        </div>
      </div> -->
    </div>
<!--    <div class="managementServices">-->
<!--      <p>健康管理服务</p>-->
<!--      <div v-if="services2.length" class="management">-->
<!--        &lt;!&ndash; <div class="lis" v-for="item in managementServices" :key="item.id">-->
<!--        </div> &ndash;&gt;-->
<!--        <el-carousel-->
<!--          :interval="5000"-->
<!--          :autoplay="true"-->
<!--          arrow="never"-->
<!--          trigger="click"-->
<!--          indicator-position="outside"-->
<!--        >-->
<!--          <el-carousel-item-->
<!--            class="lis"-->
<!--            v-for="(item, index) in services2"-->
<!--            :key="index"-->
<!--          >-->
<!--            <div class="li" v-for="items in item" :key="items.contentImg">-->
<!--              <a-->
<!--                v-if="items[0]"-->
<!--                :href="items[0].contentFile"-->
<!--                target="_blank"-->
<!--                :download="items[0].contentTitle"-->
<!--              >-->
<!--                <div>-->
<!--                  <div class="text">-->
<!--                    <p>{{ items[0].contentTitle }}</p>-->
<!--                    <img-->
<!--                      class="right"-->
<!--                      src="@/static/images/right_w.png"-->
<!--                      alt=""-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div class="pic">-->
<!--                    <img-->
<!--                      :src="items[0].contentImg ? items[0].contentImg : img"-->
<!--                      alt=""-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a-->
<!--                v-if="items[1]"-->
<!--                :href="items[1].contentFile"-->
<!--                target="_blank"-->
<!--                :download="items[1].contentTitle"-->
<!--              >-->
<!--                <div>-->
<!--                  <div class="pic">-->
<!--                    <img-->
<!--                      :src="items[1].contentImg ? items[1].contentImg : img"-->
<!--                      alt=""-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div class="text">-->
<!--                    <p>{{ items[1].contentTitle }}</p>-->
<!--                    <img-->
<!--                      class="right"-->
<!--                      src="@/static/images/right_w.png"-->
<!--                      alt=""-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </div>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
<!--      </div>-->
<!--      <div class="management" v-else>-->
<!--        <empty></empty>-->
<!--      </div>-->
<!--    </div>-->
    <div class="risk">
      <div class="disclaimer">
        <p>风险提示及免责申明</p>
        <Editor
            class="editor"
            :readonly="true"
            :content="data.contentDeclare"
            :height="0"
        ></Editor>
<!--        <ul>-->
<!--          <li v-html="data.contentDeclare"></li>-->
<!--        </ul>-->
      </div>
    </div>
    <div class="suppliers">
      <main>
        <!-- <p>健康管理服务供应商信息</p> -->
        <p>健康管理和保单管理服务供应商信息</p>
        <div class="company">
          <ul>
            <li v-for="(item, index) in data.healthEnjoySuppliers" :key="index">
              {{ item.contentName }}
            </li>
          </ul>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/TinyMce";
import { productsServices } from "@/api/productsServices";
import crumbs from "@/components/crumbs.vue";
import empty from "@/components/empty";
// import Paging from "@/components/paging.vue";
export default {
  data() {
    return {
      title: [
        {
          title: "其他服务",
          link: "",
        },
        {
          title: "享健康专区",
          link: "/productsServices/health",
        },
      ],
      loading: false,
      total: 0,
      pageNum: 0,
      pages: 0,
      data: {},
      content: "",
      productList: [], //所有数据
      totalPage: 1, // 统共页数，默认为1
      currentPage: 1, //当前页数 ，默认为1
      pageSize: 6, // 每页显示数量
      currentPageData: [], //当前页显示内容
      services: [],
      services2: [],
      img: require("@/static/images/img_news_default@2x.png"),
      value:"",
    };
  },
  components: { crumbs, empty,Editor },
  created() {
    this.request();

  },
  mounted() {
    setTimeout(() => {
      // console.log("11", this.productList);
      this.totalPage = Math.ceil(this.productList.length / this.pageSize);
      this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
      this.setCurrentPageData();
    }, 800);
  },
  methods: {

    pageData(){
      setTimeout(() => {
        // console.log("11", this.productList);
        this.totalPage = Math.ceil(this.productList.length / this.pageSize);
        this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
        this.setCurrentPageData();
      }, 800);
    },

    submit() {
      console.log(this.value);
      this.request(this.value);
      this.pageData();
    },
    // 截取
    sliceArray(array, size) {
      var result = [];
      for (var i = 0; i < Math.ceil(array.length / size); i++) {
        var start = i * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
    prevPage() {
      console.log(this.currentPage);
      if (this.currentPage == 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.currentPage--;
        this.setCurrentPageData();
      }
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        this.$message.warning("已经是最后一页了");
      } else {
        this.currentPage++;
        this.setCurrentPageData();
      }
    },
    async request(title) {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productsServices({
          timeStamp,
          sign,
          pageSize: this.pageSize,
          contentTitle:title
        });
        console.log("健康", res.data);
        if (res.data.code == 200) {
          this.data = res.data.data;
          this.total = res.data.data.healthEnjoyPlans.length;
          this.productList = res.data.data.healthEnjoyPlans;
          console.log(this.productList);
          this.services = this.sliceArray(res.data.data.healthEnjoyServers, 2);
          this.services2 = this.sliceArray(this.services, 3);
          console.log("services", this.services2);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    setCurrentPageData() {
      this.loading = true;
      let begin = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      this.currentPageData = this.productList.slice(begin, end);
      // console.log(this.currentPageData);
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: rgb(62, 62, 62);
}

.health {
  .banner {
    width: 100%;

    > .banner_box {
      width: 78%;
      height: 25.5vw;
      margin: 8rem auto 2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .health_management {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    .health_management_box{
      display: flex;
      width: 70%;
      justify-content: space-around;
      align-self: end;
    }
    .health_title {
      font-size: 4rem;
      text-align: center;
      margin-top: 6rem;
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 6rem;
      /deep/.el-input__inner {
        border: none !important;
        width: 22rem !important;
        border-bottom: 1px solid !important;
        border-radius: 0 !important;
        font-size: 2rem;
        height: auto;
      }
      /deep/.el-input {
        width: auto;
      }
      > img {
        width: 4rem;
        height: 4rem;
        margin-left: 1.5rem;
        cursor: pointer;
      }
    }
    > main {
      width: 100%;
      // height: 33vw;
      // background-color: #b5dae6;
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;

      > .main {
        width: 90%;
        // height: 55vw;
        // border: 1px solid #000;
        display: flex;
        flex-wrap: wrap;
        // height: 55vw;
        //justify-content: space-between;

        > li {
          width: 33.3333%;
          display: flex;
          justify-content: center;

          > a {
            background-color: #fff;
            // height: 48%;
            margin: 3rem 0;
            width: 22vw;
            cursor: pointer;

            > img {
              width: 22vw;
              height: 15vw;
              // height: 55%;
            }

            > div {
              padding: 2rem 2rem 2rem 0rem;
              box-sizing: border-box;
              height: 45%;

              > p {
                font-size: 2.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              > .main_box {
                font-size: 1.8rem;
                margin-top: 2rem;

                > div {
                  height: 2.5rem;
                  line-height: 2.5rem;

                  > p {
                    height: 4rem;
                    line-height: 4rem;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }

      .paging {
        display: flex;
        justify-content: space-between;
        font-size: 2rem;
        align-items: center;
        margin: 0rem 2rem 0rem;
        width: 87%;
        padding: 0;

        > div {
          display: flex;
          align-items: center;
          width: 20rem;
          justify-content: space-between;

          img {
            width: 3rem;
            height: 2rem;
            cursor: pointer;
          }
        }
      }
    }

    .remind {
      > div {
        font-size: 1.3rem;
        width: 1034px;
        margin: 4rem auto;

        a {
          color: green;
        }
      }
    }

    .obtain {
      display: flex;
      justify-content: center;
      align-items: center;

      > .obtain_btn {
        display: inline-block;

        > div {
          font-size: 1.8rem;
          white-space: nowrap;
        }

        // width: 24rem;

        background-color: #003781;
        color: #fff;
        margin: 8rem auto;
        padding: 2rem 5rem;
        cursor: pointer;
      }
    }
  }

  .managementServices {
    width: 100%;
    height: 35vw;
    background-color: #dfeff2;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;

    > p {
      height: 30%;
      font-size: 4rem;
      display: flex;
      align-items: center;
    }

    > .management {
      width: 70%;
      height: 60%;
      // border: 1px solid;
      display: flex;
      justify-content: space-between;

      .lis {
        // border: 1px solid;
        width: 100%;
        display: flex;
        //justify-content: space-between;

        > div {
          width: 31%;
          height: 100%;

          > a {
            > div {
              width: 100%;
              height: 50%;
              // background-color: #ccc;
              display: flex;

              > .pic,
              .text {
                width: 50%;
                height: 100%;
                // background-color: #409eff;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                color: #fff;

                > img {
                  width: 100%;
                  height: 100%;
                }

                > p {
                  font-size: 2.1rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                }

                > .right {
                  width: 20px;
                  height: 20px;
                }
              }

              .text {
                cursor: pointer;
                padding: 3rem;
              }
            }
          }
        }

        > div:first-child {
          .text {
            background-color: #007ab3;
          }
        }

        > div:nth-child(2) {
          margin: 0 3.333333333%;
          .text {
            background-color: #4ca46e;
          }
        }

        > div:last-child {
          .text {
            background-color: #00908d;
          }
        }
      }

      /deep/ .el-carousel--horizontal {
        overflow-x: hidden;
        width: 100%;
        height: 24vw;
      }

      /deep/ .el-carousel__button {
        display: block;
        opacity: 0.3;
        width: 10px;
        height: 3px;
        background-color: blue;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0.5rem;
      }

      /deep/ .el-carousel__indicator.is-active button {
        opacity: 1;
        // background: #003781;
      }
    }
  }
  .line{
    border-top: 1px dashed #ccc;
    width: 87%;
    margin: 7rem auto;
  }
  .risk {
    > .disclaimer {
      width: 1034px;
      margin: auto;

      > p {
        font-size: 4rem;
        text-align: center;
        //margin: 5rem 0;
      }

      > ul {
        //padding: 1rem;
        width: 1034px;

        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.5rem;
        }
      }
    }
  }

  .suppliers {
    width: 100%;

    > main {
      width: 75%;
      // height: 25vw;
      background-color: #003781;
      margin: 5rem auto 10rem;
      color: #fff;
      padding: 5rem 15rem;
      box-sizing: border-box;

      > p {
        font-size: 4rem;
        text-align: center;
        // margin-bottom: 6rem;
      }

      .company {
        display: flex;
        margin: 4rem 0;
        flex-wrap: wrap;
        //height: 10vw;

        > ul {
          display: flex;
          //flex-direction: column;
          flex-wrap: wrap;
          height: 100%;
          width: 100%;

          li {
            font-size: 1.5rem;
            height: 4rem;
            line-height: 4rem;
            // text-align: center;
            width: 38rem;
          }
        }
      }
    }
  }

  /deep/ .empty_box {
    margin: 0 auto;
  }

  ///deep/ .el-carousel__container {
  //  height: 100%;
  //}
}
.health .health_management > main .paging{
  margin: 0rem 4rem 0rem 0;
  width: 86%;
}
</style>
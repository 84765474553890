import http from "@/utils/request.js";

// 享健康
export function productsServices(data) {
  return http({
    url: "/healthEnjoy/getHealthEnjoyInfo",
    method: "get",
    params: data,
  });
}
//
export function azCustomerList(data) {
  return http({
    url: "/azCustomer/list",
    method: "post",
    data,
  });
}
// export function azCustomerList1(data) {
//   return http({
//     url: "/azCustomer/list",
//     method: "get",
//     params: data,
//   });
// }
